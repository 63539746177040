import { create } from 'zustand';

export const useLicensePlateStore = create((set) => ({
  selectedCountry: 'NO',
  inputregistrationNumber: '',
  vehicleData: null,
  caseHistory: null,
  helperText: '',
  icon: null,
  buttonEnabled: false,
  errorState: false,
  fetchErrorState: false,
  fetchLoadingState: false,

  setVehicleData: (details) => set({ vehicleData: details }),
  setCaseHistory: (details) => set({ caseHistory: details }),
  setSelectedCountry: (selectedCountry) => set({ selectedCountry }),
  setInputRegistrationNumber: (inputregistrationNumber) => set({ inputregistrationNumber }),
  setHelperText: (helperText) => set({ helperText }),
  setIcon: (icon) => set({ icon }),
  setButtonEnabled: (buttonEnabled) => set({ buttonEnabled }),
  setErrorState: (errorState) => set({ errorState }),
  setFetchErrorState: (fetchErrorState) => set({ fetchErrorState }),
  setFetchLoadingState: (fetchLoadingState) => set({ fetchLoadingState }),
  clearVehicleResults: () =>
    set({
      vehicleData: null,
      caseHistory: null,
    }),
  clearLicensePlateStore: () =>
    set({
      inputregistrationNumber: '',
    }),
}));
