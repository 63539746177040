import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Alert } from '@mui/material';
import { generateVehicleRows } from '../../utils/functions/generateVehicleRows';
import { generateOwnerRows } from '../../utils/functions/generateOwnerRows';

const OwnerInfoDialog = ({ data, open, handleClose }) => {
  const ownerData = data.owner?.[0] || {};
  const userData = data.user?.[0] || {};
  const isCompany = ownerData.idNumber && ownerData.idNumber.toString().length >= 9;

  const carRows = generateVehicleRows(data);
  const ownerRows = generateOwnerRows(ownerData, isCompany);
  const userRows = generateOwnerRows(userData, isCompany);

  const isUserMissing = !userData || Object.keys(userData).length === 0;

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg">
      <DialogContent>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 500 }} size="small" aria-label="car details">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <strong>Bilinformasjon</strong>
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carRows.map((row) => (
                  <TableRow key={row.property} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.property}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {!isUserMissing && userData && Object.keys(userData).length > 0 && (
              <Table sx={{ minWidth: 500 }} size="small" aria-label="owner details">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <strong>Forsikringstaker</strong>
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userRows.map((row) => (
                    <TableRow key={row.property} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.property}
                      </TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}

            <Table sx={{ minWidth: 500 }} size="small" aria-label="owner details">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <strong>Eierinformasjon</strong>
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ownerRows.map((row) => (
                  <TableRow key={row.property} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.property}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {isUserMissing && (
              <Alert severity="warning" sx={{ boxShadow: 2 }}>
                Forsikringstaker er ikke tilgjengelig, viser kun eierinformasjon.
              </Alert>
            )}
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OwnerInfoDialog;
