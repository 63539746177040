import { formatAddress } from '../format/formatAddress';

export const generateOwnerRows = (data, isCompany) => {
  return isCompany
    ? [
        { property: 'Firmanavn', value: data.companyName || data.lastName },
        { property: 'Organisasjonsnummer', value: data.idNumber },
        { property: 'Adresse', value: formatAddress(data.address) },
        { property: 'By', value: data.city },
        { property: 'Postnummer', value: data.zip },
      ]
    : [
        { property: 'Fornavn', value: data.firstName },
        { property: 'Etternavn', value: data.lastName },
        { property: 'Adresse', value: formatAddress(data.address) },
        { property: 'By', value: data.city },
        { property: 'Postnummer', value: data.zip },
      ];
};
