import React, { useEffect } from 'react';
import SearchResultsDisplay from '../../../UI/UserFeedback/SearchResults/SearchResultsDisplay';
import { Card, Typography, Alert } from '@mui/material';
import { LicensePlateInput } from './LicensePlateInput';
import { useLicensePlateStore } from '../../../../hooks/store/useLicensePlateStore';

export const RegistrationContainer = () => {
  const { vehicleData, fetchLoadingState, fetchErrorState } = useLicensePlateStore();

  useEffect(() => {
    console.log('Data fra TFF/PCD: ', vehicleData);
  }, [vehicleData]);

  return (
    <>
      <LicensePlateInput />
      {vehicleData && (
        <Card sx={{ boxShadow: 4, padding: 2 }}>
          <Typography variant="h3" marginBottom={2}>
            Resultater:
          </Typography>
          {!vehicleData && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              Feil: Data om kjøretøyet kunne ikke hentes. Kun sakshistorikk vises.
            </Alert>
          )}
          <SearchResultsDisplay hasSearched={true} loading={fetchLoadingState} pcdErrorMessage={fetchErrorState} rsaErrorMessage={fetchErrorState} />
        </Card>
      )}
    </>
  );
};
