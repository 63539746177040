import { CircularProgress } from '@mui/material';
import { useAxiosInstance } from '../../api/axiosInstanceTwo';
import { useLicensePlateStore } from '../store/useLicensePlateStore';
import { licensePlateConfig } from '../../options/licensePlateConfig';

export const useLicensePlateInput = () => {
  const axiosInstance = useAxiosInstance();
  const pcdUrl = `${process.env.REACT_APP_PCD_API_BASE_URL}/pcd/search`;
  const {
    selectedCountry,
    inputregistrationNumber,
    helperText,
    icon,
    buttonEnabled,
    errorState,
    fetchErrorState,
    fetchLoadingState,
    setSelectedCountry,
    setInputRegistrationNumber,
    setHelperText,
    setIcon,
    setButtonEnabled,
    setErrorState,
    setFetchErrorState,
    setFetchLoadingState,
    setVehicleData,
    setCaseHistory,
    clearLicensePlateStore,
  } = useLicensePlateStore();

  const registrationCountry = licensePlateConfig.find((c) => c.code === selectedCountry);

  const handleInputChange = (e) => {
    const registrationNumber = e.target.value.toUpperCase().replace(/\s+/g, '');
    setInputRegistrationNumber(registrationNumber);

    if (!registrationNumber) {
      clearLicensePlateStore();
      setHelperText('');
      setButtonEnabled(false);
      setErrorState(false);
      return;
    }

    if (registrationCountry) {
      if (registrationCountry.nonCarRegEx.test(registrationNumber)) {
        setHelperText('Manuelt søk er påkrevd');
        setButtonEnabled(true);
        setErrorState(false);
        setVehicleData(null);
        return;
      }

      if (registrationCountry.carRegEx.test(registrationNumber)) {
        setHelperText('Validering OK, sjekker data...');
        setErrorState(false);
        setButtonEnabled(false);
        fetchVehicleData(registrationNumber);
      } else {
        setHelperText(`Ugyldig format for ${registrationCountry.name}`);
        setButtonEnabled(false);
        setErrorState(true);
      }
    }
  };

  const fetchVehicleData = async (registrationNumber) => {
    setFetchLoadingState(true);
    setIcon(<CircularProgress color="success" />);

    try {
      const vehicleResponse = await axiosInstance.post(pcdUrl, {
        registrationNumber: registrationNumber,
        countryCode: registrationCountry.name,
      });

      if (vehicleResponse.status === 200) {
        const fetchedData = vehicleResponse.data;

        setVehicleData(fetchedData);
        setHelperText('');
        setButtonEnabled(true);
        setFetchErrorState(false);
        setFetchLoadingState(false);
      } else {
        throw new Error('Vehicle data fetch failed');
      }

      const caseHistoryResponse = await axiosInstance.get(`${process.env.REACT_APP_RSA_API_BASE_URL}?registrationNumber=${registrationNumber}`);

      if (caseHistoryResponse.status === 200) {
        const fetchedData = caseHistoryResponse.data;
        setCaseHistory(fetchedData);
        setHelperText('');
        setButtonEnabled(true);
        setFetchErrorState(false);
        setFetchLoadingState(false);
      } else {
        throw new Error('Case history fetch failed');
      }
    } catch (error) {
      setHelperText('Søket resulterte i null treff. Prøv igjen');
      setButtonEnabled(true);
      setFetchErrorState(true);
      setFetchLoadingState(false);
    }
  };

  const handleManualSearch = () => {
    if (inputregistrationNumber) {
      fetchVehicleData(inputregistrationNumber);
    }
  };

  return {
    inputregistrationNumber,
    helperText,
    icon,
    buttonEnabled: buttonEnabled && !fetchLoadingState,
    handleInputChange,
    handleManualSearch,
    selectedCountry,
    setSelectedCountry,
    inputState: errorState || fetchErrorState,
    fetchLoadingState,
  };
};
