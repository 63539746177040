import React from 'react';
import { useParams } from 'react-router-dom';
import { useRsaCaseData } from '../../hooks/query/useRsaCaseData';
import CarLoading from '../../components/Loading/CarLoading';
import { ReviewLayout } from './ReviewLayout';

export const ReviewPage = () => {
  const { id } = useParams();
  const { data: caseData, isLoading, isError } = useRsaCaseData(id);
  console.log('Case data return:', caseData);

  return (
    <>
      {isLoading && <CarLoading state="loading" />}
      {isError && <CarLoading state="error" />}
      {!isLoading && !isError && caseData && <ReviewLayout caseData={caseData} />}
    </>
  );
};
