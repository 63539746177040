import React from 'react';
import { useField } from 'formik';
import { MenuItem, Select } from '@mui/material';
import { licensePlateConfig } from '../../../../options/licensePlateConfig';
import { useLicensePlateInput } from '../../../../hooks/Search/useLicensePlateInput';

export const RegistrationCountry = () => {
  const { selectedCountry, setSelectedCountry } = useLicensePlateInput();
  const [field] = useField('searchValues.countryCode');

  return (
    <Select {...field} value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)} name="searchValues.countryCode" variant="standard" displayEmpty>
      {licensePlateConfig.map((country) => (
        <MenuItem key={country.id} value={country.code}>
          {country.code}{' '}
          <i>
            <small>({country.name})</small>
          </i>
        </MenuItem>
      ))}
    </Select>
  );
};
