import React, { useEffect } from 'react';
import { useField } from 'formik';
import { TextField, InputAdornment, FormControl } from '@mui/material';
import { RegistrationCountry } from './RegistrationCountry';
import { useLicensePlateInput } from '../../../../hooks/Search/useLicensePlateInput';
import { useLicensePlateStore } from '../../../../hooks/store/useLicensePlateStore';
import { RegistrationInputButton } from '../../../Button/RegistrationInputButton';

export const LicensePlateInput = () => {
  const [field, , helpers] = useField('searchValues.registrationNumber');
  const { helperText, handleInputChange, inputState, handleManualSearch, icon, buttonEnabled } = useLicensePlateInput();
  const { inputregistrationNumber, setInputRegistrationNumber, fetchLoadingState, fetchErrorState, vehicleData, caseHistory } = useLicensePlateStore();

  useEffect(() => {
    if (vehicleData && caseHistory) {
      console.log('Fetched data:', {
        vehicleData: {
          user: vehicleData.user,
          owner: vehicleData.owner,
          vehicle: vehicleData.vehicleData,
        },
        history: caseHistory.cases,
      });
    }
  }, [caseHistory, vehicleData]);

  const handleChange = (e) => {
    const value = e.target.value.toUpperCase().replace(/\s+/g, '');
    handleInputChange(e);
    setInputRegistrationNumber(value);
    helpers.setValue(value);
  };

  const getStyles = () => {
    if (!inputregistrationNumber) {
      return {
        borderColor: 'inherit',
        backgroundColor: 'inherit',
        helperTextColor: 'inherit',
      };
    }

    if (inputState && !fetchLoadingState) {
      return {
        borderColor: 'red',
        backgroundColor: 'inherit',
        helperTextColor: 'red',
      };
    }

    if (helperText === 'Manuelt søk er påkrevd') {
      return {
        borderColor: 'orange',
        backgroundColor: '#ffffff',
        helperTextColor: 'orange',
      };
    }

    if (vehicleData && !fetchErrorState) {
      return {
        borderColor: 'green',
        backgroundColor: '#ffffff',
        helperTextColor: 'green',
      };
    }

    if (fetchErrorState) {
      return {
        borderColor: 'red',
        backgroundColor: 'inherit',
        helperTextColor: 'red',
      };
    }

    return {
      borderColor: 'inherit',
      backgroundColor: 'inherit',
      helperTextColor: 'inherit',
    };
  };

  const styles = getStyles();

  return (
    <FormControl fullWidth>
      <TextField
        {...field}
        label="Skriv inn registreringsnummer"
        placeholder="Skriv inn registreringsnummer"
        value={inputregistrationNumber}
        onChange={handleChange}
        helperText={<span style={{ color: styles.helperTextColor }}>{helperText}</span>}
        error={inputState}
        sx={{
          '& .MuiOutlinedInput-root fieldset': {
            border: `2px solid ${styles.borderColor}`,
          },
          '& .MuiOutlinedInput-root:hover fieldset': {
            borderColor: styles.borderColor,
          },
          '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: styles.borderColor,
          },
          '& .MuiOutlinedInput-input': {
            marginLeft: 1,
          },
          '& .MuiOutlinedInput-root': {
            paddingRight: '0px',
          },
          '& .MuiFormHelperText-root': {
            color: styles.helperTextColor,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <RegistrationCountry />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <RegistrationInputButton i={icon} state={buttonEnabled} manualAction={handleManualSearch} loading={fetchLoadingState} />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
