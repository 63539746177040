export const generateVehicleRows = (data) => {
  const { insurance, vehicleData } = data;

  const { technicalData } = vehicleData;

  console.log('data', data);
  console.log('incerance', insurance);
  console.log('vehicleData', vehicleData);

  const transmission = technicalData.automaticTransmission;
  const fourWheelDrive = technicalData.fourWheelDrive;

  return [
    { property: 'Registreringsnummer', value: data.registrationNumber },
    { property: 'Merke', value: vehicleData.make },
    { property: 'Modell', value: vehicleData.model },
    { property: 'Dato for første registrering', value: vehicleData.dateOfFirstRegistration ? new Date(vehicleData.dateOfFirstRegistration).toLocaleDateString() : '' },
    { property: 'Vin', value: vehicleData.vin },
    { property: 'Forsikringsselskap', value: insurance?.name },
    { property: 'Karosseritype', value: vehicleData.bodyType },
    { property: 'Drivstofftype', value: technicalData.fuelTypes?.[0] },
    { property: 'Egenvekt', value: technicalData.weight + ' KG' },
    { property: 'Bredde', value: vehicleData.width + ' CM' },
    { property: 'Lengde', value: vehicleData.length + ' CM' },
    { property: 'Farge', value: vehicleData.colour },
    { property: 'Totalt antall aksler', value: technicalData.totalNumberOfAxles },
    { property: 'Firhjulsdrift', value: fourWheelDrive ? 'Ja' : 'Nei' },
    { property: 'Girkasse', value: transmission ? 'Automatisk' : 'Manuell' },
  ];
};
