import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const RegistrationInputButton = ({ i, state, manualAction, loading }) => {
  return (
    <Button
      variant="contained"
      size="small"
      onClick={manualAction}
      disabled={!state || loading}
      sx={{
        borderRadius: '0px 4px 4px 0px',
        padding: '14px 30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? <CircularProgress size={24} color="secondary" /> : <SearchIcon fontSize="small" />}
    </Button>
  );
};
